import clsx from "clsx"
import React from "react"

const ViewDetailsRight = ({ className, blue }) => (
  <svg
    className={clsx("location-icon", className)}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 3L14 8M14 8L9 13M14 8H2"
      stroke="#323247"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ViewDetailsRight
