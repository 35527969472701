import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import SubscriptionForm from "../../../static/forms/subscription_form.json"
import CustomSlider from "../CustomSlider/CustomSlider"
import DefaultForm from "../forms/default-form-layout"
import "./SubscriptionComponent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const SubscriptionComponent = ({ data }) => {
  const [show, setShow] = useState(false)
  const [itemValue, setItemValue] = useState("")

  const handleModal = (item) => {
    setShow(true)
    setItemValue(item)
  }
  const closeModal = () => {
    setShow(false)
  }
  return (
    <div className="subscription-wrapper">
      <Container>
        <div className="subscription-section">
          <h2 className="title">{data?.title}</h2>
          <p className="description">
            <ContentModule Content={data?.content?.data?.content} />
          </p>

          <CustomSlider
            // arrows={isDesktop}
            slidecount={data?.column_count === "two" ? 2 : 3}
            className="card-items-slider subscription-list"
          >
            {data.subscription_item?.length > 0 &&
              data.subscription_item.map((item, index) => {
                return (
                  <div className="sub-item" key={item.title}>
                    <h3>{item?.title}</h3>
                    <p className="sub-title">{item?.sub_title}</p>
                    <p className="list-features">
                      <ContentModule Content={item?.content?.data?.content} />
                    </p>
                    <button
                      onClick={() => handleModal(item)}
                      className="button button-primary-color"
                    >
                      Get Started
                    </button>
                  </div>
                )
              })}
          </CustomSlider>
        </div>
      </Container>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>{`${itemValue.title}`}</h2>
            <DefaultForm fields={SubscriptionForm} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SubscriptionComponent
