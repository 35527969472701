import dateFormat from "dateformat"
import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { newsUrl } from "../../lib/urls"
import { CustomSlider } from "../CustomSlider/CustomSlider"
import "./NewsSliderCard1.scss"

const NewsSliderCard2 = ({ blogData, pageData }) => {
  if (!blogData?.length) return

  return (
    <div className="news-slider-wrapper white">
      <Container>
        <div className="news-slider-module">
          <h2>{pageData?.title}</h2>
          <CustomSlider className="news-slider" slidecount={3}>
            {blogData?.map((item) => {
              return (
                <div className="slider-card" key={item?.slug}>
                  <div className="image-section">
                    <Link className="title" to={`${newsUrl}${item?.slug}`}>
                      <img src={item?.tile_image?.url} alt="pop" />
                    </Link>
                  </div>
                  <div className="content-section">
                    <Link className="title" to={`${newsUrl}${item?.slug}`}>
                      {item?.title}
                    </Link>
                    <p className="date">
                      {dateFormat(item?.date, "dd mmmm yy")}
                    </p>
                  </div>
                </div>
              )
            })}
          </CustomSlider>
        </div>
      </Container>
    </div>
  )
}

export default NewsSliderCard2
