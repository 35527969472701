import React from "react"
import { Container } from "react-bootstrap"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { parseHTML } from "../../lib/utils"
import Animation from "../elements/Animation"
import "./Timeline.scss"

const TimelinePoint = ({ index }) => {
  return <p className="timeline-point">{index} </p>
}

const TimelineNumbered = (props) => {
  const { isTablet } = useDeviceMedia()
  React.useEffect(() => {
    //  select the first item with class "timeline-item-wrap"
    const lastTimelineItem = document.querySelector(
      ".timeline-item-wrap:last-child"
    )

    // get height of the timeline item
    const lastItemHeight = lastTimelineItem?.clientHeight

    // add a css rule to the document head
    const addRule = ((style) => {
      const { sheet } = document.head.appendChild(style)
      return (selector, css) => {
        const propText =
          typeof css === "string"
            ? css
            : Object.keys(css)
                .map((p) => {
                  return `${p}:${p === "content" ? `'${css[p]}'` : css[p]}`
                })
                .join(";")
        sheet.insertRule(`${selector}{${propText}}`, sheet.cssRules.length)
      }
    })(document.createElement("style"))

    // add top value to the timeline item
    addRule(".vertical-timeline::before", {
      top: "74px",
      height: `calc(100% - ${lastItemHeight-24}px)`,
    })
  }, [])

  // if (props?.step?.length > 0) return null

  // const { step } = props?.step

  return (
    <Animation className="timeline-wrap section-p timeline-numbered">
      <Container className="timeline-container">
        <div className="timeline-section">
          <VerticalTimeline animate={false}>
            {props?.step?.map((item, index) => (
              <VerticalTimelineElement
                className={index === 0 ? "first-item timeline-item-wrap" : index === props?.step?.length - 1 ? "last-item timeline-item-wrap" : "timeline-item-wrap"}
                key={item.id}
                icon={<TimelinePoint index={index + 1} />}
              >
                <Animation
                  animateInType={
                    // eslint-disable-next-line no-nested-ternary
                    isTablet ? "right" : index % 2 === 0 ? "left" : "right"
                  }
                  className="timeline-item"
                >
                  {item.icon && (
                    <img src={item.icon.url} alt="timeline-img" />
                  )}
                  <div className="timeline-item-content">
                    <p className="title">{item.title}</p>
                    <div className="description">
                      {parseHTML(item.content?.data?.content)}
                    </div>
                  </div>
                </Animation>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </Container>
    </Animation>
  )
}

export default TimelineNumbered
