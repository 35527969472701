import React from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap"

import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const Accordian = (props) => {
  return (
    <div className="accordion-wrapper">
      <Container>
        <Row>
          <Col xl={12}>
            <h2 className="big-title">{props?.heading}</h2>
            <Accordion className="accordion">
              {props.add.map((item, index) => {
                 const imagename = "page.accordion_add_icon.image"
 
               let processedImages = JSON.stringify({})
               if (props?.imagetransforms?.accordion_add_icon_image_Transforms) {
                 processedImages =
                   props?.imagetransforms?.accordion_add_icon_image_Transforms
               }
                return(
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item?.icon?.url &&
                  // <img src={item?.icon?.url} alt="icon" />
                  <ImageModule
                  ImageSrc={item?.icon}
                  altText={item?.icon?.alternativeText}
                  imagetransforms={processedImages}
                  renderer="srcSet"
                  imagename={imagename}
                  strapi_id={props?.id}
                  classNames="img-card"
                />
                  } {item.title}</Accordion.Header>
                  <Accordion.Body>
                    {item.content && (
                      <ContentModule Content={item.content?.data?.content} />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                )
                })
              }
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Accordian
