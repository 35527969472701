import loadable from "@loadable/component"
import { Link, graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { customStylesNews } from "../Common/utils"
import ViewDetailsRight from "../icons/ViewDetailsRight"
import "./OurClients.scss"

const {
  usePagination,
} = require("@starberry/gatsby-theme-utils/Hooks/UsePagination")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const Select = loadable(() => import("react-select"))
const OurClients = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiClient(filter: { publish: { eq: true } }) {
        edges {
          node {
            title
            country
            link {
              custom_link
              cta_label
            }
            content {
              data {
                content
              }
            }
            strapi_id
            imagetransforms {
              image_Transforms
            }
            image {
              url
            }
          }
        }
      }
    }
  `)

  const clientsData = data.allStrapiClient.edges

  const [filter, setFilter] = useState("all")
  const [projects, setProjects] = useState(clientsData)
  const [filteredList, setFilteredList] = useState(clientsData)
  const selectOptions = [{ value: "all", label: "All Locations" }]
  const [dubaiCount, setDubaiCount] = useState(
    _.filter(clientsData, function (o) {
      return o.node.country?.includes("Dubai")
    })
  )
  const [united_kingdomCount, setUnitedKingdomCount] = useState(
    _.filter(clientsData, function (o) {
      return o.node.country?.includes("United_Kingdom")
    })
  )
  const [set, setClass] = useState(false)

  const newClass = (event) => {
    const parent = document.getElementById("filter-link")
    parent.classList.add("filter-active")
    setClass(false)
    const allChildElements = parent.querySelectorAll(".nav-link")
    for (const box of allChildElements) {
      box.classList.remove("active")
    }
    event.target.classList.add("active")
  }

  const applyCategory = (filt) => {
    if (filt !== "all") {
      setProjects([])
      const filtered = _.filter(clientsData, function (o) {
        return o.node.country?.includes(filt)
      })
      setProjects(filtered)
      setFilteredList(filtered)
    } else {
      setProjects(clientsData)
      setFilteredList(clientsData)
    }
  }

  // for pagination
  const itemsPerPage = 50
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredList,
    itemsPerPage,
  })
  // for pagination

  useEffect(() => {
    if (dubaiCount.length > 0) {
      selectOptions.push({ value: "Dubai", label: "Dubai" })
    }
    if (united_kingdomCount.length > 0) {
      selectOptions.push({ value: "United_Kingdom", label: "United Kingdom" })
    }
  }, [filteredList])

  const ImageRenderList = ({ item, imagename }) => {
    let processedImages = JSON.stringify({})
    if (item?.imagetransforms?.image_Transforms) {
      processedImages = item?.imagetransforms?.image_Transforms
    }

    return (
      item.image?.url &&
      <ImageModule
        ImageSrc={item.image}
        title={item.title}
        altText={`${item.title}`}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename={imagename}
        strapi_id={item.strapi_id}
      />
    )
  }
  return (
    <div className="our-clients-wrap section-l-m" id="our-clients">
      <div className="mobile-tab-wrapper d-md-none">
        <Select
          options={selectOptions}
          isSearchable={false}
          onChange={(e) => {
            setFilter(e.value)
            applyCategory(e.value)
          }}
          placeholder="All Locations"
          className="select-control"
          classNamePrefix="react-select"
          styles={customStylesNews}
          components={{
            DropdownIndicator: () => (
              <i className="icon icon-select-dropdown-dark" />
            ),
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={10}
        className="tab-wrapper"
      >
        <Container>
          <ul
            className="inner-tab d-md-flex d-none nav nav-tabs"
            id="filter-link"
          >
            <li className="nav-item">
              <button
                type="button"
                className="nav-link active"
                onClick={(event) => {
                  setFilter("all")
                  applyCategory("all")
                  newClass(event)
                }}
              >
                All Locations
              </button>
            </li>
            {dubaiCount.length > 0 && (
              <li className="nav-item">
                <button
                  type="button"
                  className="nav-link"
                  onClick={(event) => {
                    setFilter("dubai")
                    applyCategory("Dubai")
                    newClass(event)
                  }}
                >
                  Dubai
                </button>
              </li>
            )}
            {united_kingdomCount.length > 0 && (
              <li className="nav-item">
                <button
                  type="button"
                  className="nav-link"
                  onClick={(event) => {
                    setFilter("United_Kingdom")
                    applyCategory("United_Kingdom")
                    newClass(event)
                  }}
                >
                  United Kingdom
                </button>
              </li>
            )}
          </ul>
        </Container>
      </ScrollAnimation>
      <Container className="our-clients-container">
        <Row>
          <Col>
            {currentItems.length > 0 ? (
              <div className="client-card-wrapper-main">
                {currentItems?.map(({ node }, index) => {
                  return (
                    <div className="client-item">
                      <div>
                        <ImageRenderList
                          item={node}
                          imagename="client.image.tile_image"
                        />
                      </div>
                      <div className="text-bk">
                        <h4>{node.title}</h4>
                        <ContentModule Content={node?.content?.data?.content} />
                        <Link
                          to={node.link?.custom_link}
                          className="view-details"
                        >
                          {node.link?.cta_label}
                          <span className="icon-border">
                            <ViewDetailsRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <p>Sorry, noresults found, please check after sometimes</p>
            )}
          </Col>
          {/* <InnerPagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredList.length}
            setCurrentPage={setCurrentPage}
          /> */}
        </Row>
      </Container>
    </div>
  )
}

export default OurClients
