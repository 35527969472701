import React, { useState } from "react"
import loadable from "@loadable/component"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import FeaturedPropertiesCard from "../FeaturedPropertiesCard/FeaturedPropertiesCard"
import { sliderSettings } from "../SliderSettings/SliderSettings"
import "./CommercialFeatured.scss"

const Slider = loadable(() => import("react-slick"))

const KEYS = {
  sale: "for-sale",
  rent: "for-rent",
}

const options = [
  { label: "For Sale", value: "for-sale" },
  { label: "For Rent", value: "for-rent" },
]

const CommercialFeatured = ({ moduleData, PageData }) => {
  const data = useStaticQuery(graphql`
    query {
      commsales: allStrapiProperty(
        filter: {
          publish: { eq: true }
          status: { eq: "For Sale" }
          department: { eq: "commercial" }
          search_type: { eq: "sales" }
        }

        sort: { fields: updatedAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
              }
            }
            floorarea_min
            floorarea_type
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }
      commlettings: allStrapiProperty(
        filter: {
          publish: { eq: true }
          department: { eq: "commercial" }
          search_type: { eq: "lettings" }
        }
        sort: { fields: updatedAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
              }
            }
            floorarea_min
            floorarea_type
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }
    }
  `)

  const [saleLists, setSaleLists] = useState(data?.commsales?.edges)
  const [rentLists, setRentLists] = useState(data?.commlettings?.edges)

  const [selectedOption, setSelectedOption] = useState(
    saleLists?.length > 0 ? "for-sale" : "for-rent"
  )
  const [key, setKey] = useState(saleLists?.length > 0 ? KEYS.sale : KEYS.rent)

  const { isMobile } = useDeviceMedia()

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const SliderTab = ({ items }) => {
    return (
      <div>
        <Slider className="featured-properties-slider" {...sliderSettings}>
          {items?.map(({ node }, i) => {
            let details_path = "/commercial-property-for-sale"
            if (node?.search_type == "lettings") {
              details_path = "/commercial-property-for-rent"
            }

            let processedImages = JSON.stringify({})
            if (node?.imagetransforms?.images_Transforms) {
              processedImages = node?.imagetransforms?.images_Transforms
            }

            const image_all =
              node?.department === "new_development" && node?.images?.length > 0
                ? node?.images
                : node?.images?.strapi_json_value?.length > 0
                ? node?.images?.strapi_json_value
                : ""
            // console.log(node?.department)
            return (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={i * 100}
                animateOnce
                offset={10}
              >
                <FeaturedPropertiesCard
                  details_path={details_path}
                  {...node}
                  img={image_all}
                  processedImages={processedImages}
                  status="Under Offer"
                  bedroom={node?.bedroom}
                  bathroom={node?.bathroom}
                  floorarea_min={node?.floorarea_min}
                  floorarea_type={node?.floorarea_type}
                  address={node?.display_address}
                  priceTag={node?.price_qualifier}
                  price={node?.price?.toLocaleString()}
                  title={node?.title}
                  slug={node?.slug}
                  crmID={node?.crm_id}
                  search_type={node?.search_type}
                  department={node?.department}
                  strapi_id={node?.strapi_id}
                />
              </ScrollAnimation>
            )
          })}
        </Slider>
      </div>
    )
  }

  return saleLists?.length > 0 || rentLists?.length > 0 ? (
    <section className="featured-properties-wrapper-1 section-p">
      <Container>
        <Row>
          <Col>
            <div className="relative">
              <div className="featured-heading-wrapper">
                <h2>{moduleData?.title}</h2>
              </div>
              {isMobile ? (
                <>
                  <div className="select_feature">
                    <Form.Select
                      onChange={handleSelectChange}
                      value={selectedOption}
                      aria-label="Default select example"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  {selectedOption === "for-sale" && saleLists?.length > 0 && (
                    <div className="featured-properties-tab-section">
                      <SliderTab items={saleLists} />
                    </div>
                  )}
                  {selectedOption === "for-rent" && rentLists?.length > 0 && (
                    <SliderTab items={rentLists} />
                  )}
                </>
              ) : (
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="featured-properties-tab-section"
                >
                  {saleLists?.length > 0 && (
                    <Tab eventKey={KEYS.sale} title="For Sale">
                      <SliderTab items={saleLists} />
                    </Tab>
                  )}
                  {rentLists?.length > 0 && (
                    <Tab eventKey={KEYS.rent} title="For Rent">
                      <SliderTab items={rentLists} />
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  ) : null
}

export default CommercialFeatured
