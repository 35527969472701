import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import CommunityCustomSlider from "../CustomSlider/CommunityCustomSlider"
import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const GlobalCitizionship = (props) => {
  return (
    <section className="global-citizion">
      <Container>
        <Row>
        <Col xl={9} lg={8} md={8}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
          </Col>
          {/* <Col xl={3} lg={4} md={4}>
            <Link
              className="button button-secondary-outline"
              to={`/${PageLinks.community}`}
            >
              More Communities 
            </Link>
          </Col> */}
        </Row>
        {props?.new_item?.length > 0 ? (
          <CommunityCustomSlider
            slidecount={props?.slide_count ? props?.slide_count : 1}
            activateArrows={true}
            className="community-card-wrapper"
          >
            {props?.new_item?.map((item, i) => {
              const imagename = "page.citizenship.image"

              let processedImages = JSON.stringify({})
              if (props?.imagetransforms?.citizenship_Transforms) {
                processedImages =
                  props?.imagetransforms?.citizenship_Transforms
              }
              return (
                <div className="card">
                  <div className="item-card">
                    <div className="image-card">
                      {/* <Link to={`/${PageLinks.community}/${item.slug}`}> */}
                        <ImageModule
                          ImageSrc={item?.image}
                          altText={item?.image?.alternativeText}
                          imagetransforms={processedImages}
                          renderer="srcSet"
                          imagename={imagename}
                          strapi_id={props?.id}
                          classNames="img-card"
                        />
                      {/* </Link> */}
                    </div>
                    <div>
                      <h6>
                        {/* <Link to={`/${PageLinks.community}/${item.slug}`}> */}
                          {item.title}
                        {/* </Link> */}
                      </h6>
                      <ContentModule
                        Content={item.description?.data?.description}
                      />
                      <div class="cta-card">
                        <CTALink class="cta-link-item" link={item?.cta_link? item?.cta_link:{ external_link: item?.cta_custom_link}}>
                          {item?.cta_title && <span className="cta-link-title">{item?.cta_title}</span>}
                        </CTALink>
                        <CTALink class="cta-link-item" link={item?.cta_link? item?.cta_link:{ external_link: item?.cta_custom_link}}>
                          <span className="icon icon-bg-arrow-right" />
                        </CTALink>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </CommunityCustomSlider>
        ) : null}
      </Container>
    </section>
  )
}

export default GlobalCitizionship
