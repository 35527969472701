import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Accordian from "../components/AccordianComponent/Accordion"
import Banner from "../components/Banner/Banner"
import GridGallery from "../components/GridGallery/GridGallery"
import ImageCard from "../components/ImageCard/ImageCard"
import NewsSliderCard1 from "../components/NewsSliderCard1/NewsSliderCard1"
import NewsSliderCard2 from "../components/NewsSliderCard1/NewsSliderCard2"
import OurClients from "../components/OurClients/OurClients"
import PlainContent from "../components/PlainContent/PlainContent"
import GlobalCitizionship from "../components/PopularCommunity/GlobalCitizionship"
import PopularCommunity from "../components/PopularCommunity/PopularCommunity"
import MortageCalc from "../components/PropertyDetailCalc/MortgageCalc"
import ShowcaseComponent from "../components/ShowcaseComponent/ShowcaseComponent"
import SubscriptionComponent from "../components/SubscriptionComponent/SubscriptionComponent"
import TimelineNumbered from "../components/Timeline/TimelineNumbered"
import SocialTab from "../components/SocialTab/SocialTab"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CommercialFeatured from "../components/CommercialFeatured/CommercialFeatured"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const FeaturedProperties = loadable(() =>
  import("../components/FeaturedProperties/FeaturedProperties")
)
const IntroModule = loadable(() =>
  import("../components/IntroModule/IntroModule")
)
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const TileBlockRight = loadable(() =>
  import("../components/TileBlockRight/TileBlockRight")
)

const LandingPageTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const _siteData = data?.strapiSiteConfig
  const blogData =
    data?.allStrapiBlog?.edges?.length > 0 ? data?.allStrapiBlog?.edges : ""

  const [renderComponent, setRenderComponent] = useState(false)
  // console.log(typeof window !== "undefined" && window.location.hash)
  const myRef = useRef(null)
  const myRefc = useRef(null)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    setTimeout(() => {
    if(typeof window !== "undefined" && window.location.hash === "#mortgage-calculator"){
      
      myRef?.current?.scrollIntoView({ behavior: "smooth" })
    }
    if(typeof window !== "undefined" && window.location.hash === "#global-citizion"){
      
      myRefc?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, 1000)
  })

  return (
    <Layout dark popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top" />
      <BreadcrumbModule
        subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
        subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
        parentlabel={
          PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
        }
        parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
        title={PageData.title}
        tag="menu"
      />
      {PageData.banner && (
        <Banner
          title={PageData.title}
          {...PageData.banner}
          id={PageData.strapi_id}
          imagetransforms={PageData?.imagetransforms}
        />
      )}

      {PageData?.add_page_modules?.length > 0 &&
        PageData?.add_page_modules?.map((module, index) => {
          return (
            <div key={[index]} className="landing-page">
              {/* {module.strapi_component === "page-modules.text-module" && <PatternBanner
                            tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                            {...module}
                        />} */}
              {module.strapi_component === "page-modules.stat-with-content" && (
                <IntroModule
                  tag="landing"
                  {...module}
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                />
              )}
              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "left" && (
                  <TileBlock
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )}

              {module.strapi_component === "components.market-banner" && (
                <ShowcaseComponent
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                  data={module}
                  guideLink={PageData?.banner?.guide_link?.url}
                  title={PageData?.banner?.banner_title}
                />
              )}
              {module.strapi_component === "page-modules.subscribe-module" && (
                <SubscriptionComponent data={module} />
              )}

              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "right" && (
                  <TileBlockRight
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    data={PageData}
                  />
                )}
              {module?.strapi_component === "components.citizenship" && (
                <><div ref={myRefc} id="scroll-margin" />
                <GlobalCitizionship {...module}                     id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms} />
              </>)}

{module?.strapi_component === "components.community" && (
                <PopularCommunity {...module} />
              )}

              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "featured_properties" &&
                (PageData?.title === "Commercial" ||
                  PageData?.title === "Buy - Commercial" ||
                  PageData?.title === "Sell - Commercial" ||
                  PageData?.title === "Rent - Commercial") && (
                  <CommercialFeatured moduleData={module} PageData={PageData} />
                )}

              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "featured_properties" &&
                (PageData?.title !== "Commercial" &&
                  PageData?.title !== "Buy - Commercial" &&
                  PageData?.title !== "Sell - Commercial" &&
                  PageData?.title !== "Rent - Commercial") && (
                  <FeaturedProperties {...module} />
                )}

           
              
              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "our_clients" && (
                  <OurClients {...module} />
                )}
              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "mortgage_calculator" && (
                  <div className="mortgage-page-calculator">
                    <div ref={myRef} id="scroll-margin" />
                    <Container>
                      <Row>
                        <Col xl={12}>
                          <MortageCalc isPage propertyPrice="500000" />
                        </Col>
                      </Row>
                    </Container>
                  </div>
             
             )}
                    {module?.strapi_component === "components.stepper" && (
             <TimelineNumbered {...module} />
             )}
         
              {module?.strapi_component === "components.partner" && (
                <GridGallery {...module} />
              )}
              {module?.strapi_component === "page-modules.plain-content" && (
                <PlainContent {...module} />
              )}

              {module?.strapi_component === "components.blogs" && (
                <NewsSliderCard2 blogData={module?.blogs} pageData={module} />
              )}

              {module?.strapi_component === "page-modules.accordion" &&
                renderComponent && <Accordian {...module}  id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms} />}
              {module?.strapi_component === "page-modules.image-with-page" &&
                renderComponent && (
                  <ImageCard
                    {...module}
                    slide_count="4"
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )}
              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "blog" &&
                renderComponent && (
                  <NewsSliderCard1 blogData={blogData} pageData={module} />
                )}
              {module.strapi_component === "page-modules.global-module" &&
                module.select_module === "google_reviews_all" && (
                  <SocialTab {...module} social="google" />
                )}
            </div>
          )
        })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  const _siteData = data?.strapiSiteConfig

  // var schemaSameAs = ''
  // if (siteData?.facebook_link.length > 1) {
  //   schemaSameAs = siteData?.facebook_link + ','
  // }
  // if (siteData?.twitter_link.length > 1) {
  //   schemaSameAs += siteData?.twitter_link + ','
  // }
  // if (siteData?.instagram_link.length > 1) {
  //   schemaSameAs += siteData?.instagram_link + ','
  // }
  // if (siteData?.linkedin_link.length > 1) {
  //   schemaSameAs += siteData?.linkedin_link + ','
  // }

  // var ldJson = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   "url": process.env.GATSBY_SITE_URL,
  //   "name": process.env.GATSBY_SITE_NAME,
  //   "alternateName": process.env.GATSBY_SITE_NAME,
  //   "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
  //   "contactPoint": {
  //     "@type": "ContactPoint",
  //     "telephone": siteData?.mobile_device_phone,
  //     "areaServed": process.env.GATSBY_DEFAULT_AREA,
  //     "contactType": "Sales"
  //   },
  //   "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  // };

  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    >
      {/* <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        /> */}
    </SEO>
  )
}

export default LandingPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          strapi_component
          heading
          description {
            data {
              description
            }
          }
          add {
            title
            icon{
              url
            }
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          id
          title
          strapi_component
          content {
            data {
              content
            }
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_PARTNER {
          id
          title
          description {
            data {
              description
            }
          }
          add_client {
            image {
              url
            }
            custom_link
            cta_link {
              slug
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_WITH_PAGE {
          id
          secondary_title
          strapi_component
          primary_title
          bg_color
          choose_spacing
          card_items {
            image {
              url
              alternativeText
            }
            custom_link
            cta_label
            cta_link {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_COMMUNITY {
          id
          title
          strapi_component
          add_items {
            slug
            strapi_id
            title
            description {
              data {
                description
              }
            }
            tail_image {
              url
            }
            imagetransforms {
              popular_section_Transforms
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_STAT_WITH_CONTENT {
          id
          title
          strapi_component
          stat_with_data {
            stats_description
            stats_title
          }
          content {
            data {
              content
            }
          }
          cta_btn {
            link_type
            cta_label
            cta_link {
              slug
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_BLOGS {
          id
          strapi_component
          title
          blogs {
            title
            publish
            strapi_id
            slug
            publish
            tile_image {
              url
            }
            date
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_CITIZENSHIP {
          id
          title
          strapi_component
          new_item {
            title
            description {
              data {
                description
              }
            }
            image {
              url
              alternativeText
            }
            cta_custom_link
            cta_title
            cta_link {
              link_type
              slug
               strapi_parent {
                  slug
                }
            }
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_STEPPER {
          id
          strapi_component
          step {
            title
            content {
              data {
                content
              }
            }
            icon {
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_MARKET_BANNER {
          ...MarketBannerFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
          ...ImageAndContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_SUBSCRIBE_MODULE {
          ...SubscriptionFragment
        }
      }
    }

    allStrapiBlog(
      limit: 10
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          publish
          strapi_id
          slug
          publish
          tile_image {
            url
          }
          date
        }
      }
    }
  }
`
