import React from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./GridGallery.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const GridGallery = (props) => {
  return (
    <div className="grid-gallery-text-module">
      <Container>
        <div className="text-section_landing">
          <h2 className="heading">{props?.title}</h2>
          <p className="description">
            <ContentModule Content={props?.description?.data?.description} />
          </p>
          <CustomSlider
            slidecount={5}
            className="image-grid"
            slideSpeed
          >
            {props?.add_client?.map((list, index) => {
              return (
                <div className="image-list">
                  <img
                    src={list?.image?.url}
                    alt="Grid Gallery - haus & haus"
                  />
                </div>
              )
            })}
          </CustomSlider>
        </div>
      </Container>
    </div>
  )
}

export default GridGallery
