import loadable from "@loadable/component"
import { Link } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Modal, Row } from "react-bootstrap"
import CareerFormFields from "../../../static/forms/careers_form.json"
// import GoogleReview from "../GoogleReview/GoogleReview";
import BannerSearch from "../BannerSearch/BannerSearch"
import RegisterInterestModalWithTrigger from "../RegisterInterestModal/RegisterInterestModal"
import "./assets/styles/_index.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const Banner = (props) => {
  const imagename = "page.banner_section_banner_image.landing_banner_image1"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages =
      props?.imagetransforms?.banner_section_banner_image_Transforms
  }
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  return (
    <section className="banner d-xl-flex align-items-center landing-banner">
      <ImageModule
        ImageSrc={props?.image}
        altText={`${
          props?.image?.alternativeText
            ? props?.image?.alternativeText
            : props?.banner_title
            ? props?.banner_title
            : props?.title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={props?.id}
        classNames="img-fluid banner-img"
      />
      {/* <img src={props.bannerImg} className="img-fluid banner-img" alt="" /> */}
      <div className="overlay-bg" />

      <Container className="banner-search-container">
        <Row>
          <Col xl={8}>
            {props?.banner_title && (
              <ScrollAnimation
                delay={100}
                animateIn="animate__slideInUp"
                animateOnce
              >
                <h1>
                  <ContentModule Content={props?.banner_title} />
                </h1>
              </ScrollAnimation>
            )}
            {props?.officetag === "office-details" ? (
              <div className="office-details-wrapper">
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={300}
                  animateOnce
                  offset={10}
                >
                  <div className="office-landing-card-mobile">
                    <a href="tel:">07891 219 374</a>
                  </div>
                  <div className="office-landing-card-email">
                    <Link to="/" className="link-underline">
                      location@mira.co.uk
                    </Link>
                  </div>
                  <div className="office-landing-card-text">
                    Mira, High Street, LN1 2AB
                  </div>
                </ScrollAnimation>
              </div>
            ) : (
              props?.banner_content && (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={300}
                  animateOnce
                  offset={10}
                >
                  <ContentModule
                    Content={props?.banner_content?.data?.banner_content}
                  />
                </ScrollAnimation>
              )
            )}

            {
              // props.banner_content && <ContentModule Content={props.banner_content?.data?.banner_content} />
            }

            {props?.show_search && <BannerSearch title={props?.title} />}
            {props?.pr_cta_title && (
              <CTALink
                class="button button-primary-color banner_primary_cta"
                link={props?.pr_cta_link}
                title={props?.pr_cta_title}
              />
            )}

            <ul className="list-inline button-list">
              {props?.ctas_intro_text && (
                <p className="banner_intro_text">{props?.ctas_intro_text}</p>
              )}
              {props?.cta_1_title &&
                (props?.cta_1_link || props?.cta1_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={400}
                      animateOnce
                      offset={10}
                    >
                      {props?.isCareer ? (
                        <a
                          href="javascript:void(0);"
                          className="button button-primary-color"
                          onClick={props?.executeScroll}
                        >
                          {props?.cta_1_title}
                        </a>
                      ) : (
                        <CTALink
                          class="button button-primary-color"
                          link={
                            props?.cta_1_link
                              ? props?.cta_1_link
                              : { external_link: props?.cta1_custom }
                          }
                          title={props?.cta_1_title}
                          target_window={props?.cta_1_link?.target_window}
                        />
                      )}
                    </ScrollAnimation>
                  </li>
                )}
              {props?.cta_2_title &&
                (props?.cta_2_link || props?.cta2_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={500}
                      animateOnce
                      offset={10}
                    >
                      {props?.isCareer ? (
                        <a
                          href="javascript:void(0);"
                          className="button button-secondary-outline"
                          onClick={() => handleModal()}
                        >
                          {props?.cta_2_title}
                        </a>
                      ) : (
                        <CTALink
                          class="button button-secondary-outline"
                          link={
                            props?.cta_2_link
                              ? props?.cta_2_link
                              : { external_link: props?.cta2_custom }
                          }
                          title={props?.cta_2_title}
                          target_window={props?.cta_2_link?.target_window}
                        />
                      )}
                    </ScrollAnimation>
                  </li>
                )}
              {props?.cta_3_title && (
                <li className="list-inline-item">
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    delay={400}
                    animateOnce
                    offset={10}
                  >
                    {props?.cta_3_title === "Register your interest" ? (
                      <RegisterInterestModalWithTrigger
                        className="button button-secondary-outline"
                        buttonText={props?.cta_3_title}
                      />
                    ) : (
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          props?.cta_3_link
                            ? props?.cta_3_link
                            : { external_link: props?.cta3_custom }
                        }
                        title={props?.cta_3_title}
                        target_window={props?.cta_3_link?.link_type}
                      />
                    )}
                  </ScrollAnimation>
                </li>
              )}
              {props?.cta_4_title &&
                (props?.cta_4_link || props?.cta4_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={400}
                      animateOnce
                      offset={10}
                    >
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          props?.cta_4_link
                            ? props?.cta_4_link
                            : { external_link: props?.cta4_custom }
                        }
                        title={props?.cta_4_title}
                        target_window={props?.cta_4_link?.target_window}
                      />
                    </ScrollAnimation>
                  </li>
                )}
              {props?.cta_5_title &&
                (props?.cta_5_link || props?.cta5_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={400}
                      animateOnce
                      offset={10}
                    >
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          props?.cta_5_link
                            ? props?.cta_5_link
                            : { external_link: props?.cta5_custom }
                        }
                        title={props?.cta_5_title}
                        target_window={props?.cta_5_link?.target_window}
                      />
                    </ScrollAnimation>
                  </li>
                )}
            </ul>
            {/* <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                            <GoogleReview />
                        </ScrollAnimation> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="more-filters-modal team-form"
              id="filter-modal"
              // fullscreen={"xl-down"}
              backdrop="static"
            >
              <button
                className="cancel-button"
                type="button"
                onClick={() => closeModal()}
              >
                <i className="icon black-cancel" />
              </button>
              <Modal.Body>
                <div className="modal-body-container">
                  <h2>Send us your CV</h2>
                  <DefaultForm
                    fields={CareerFormFields}
                    formtagclassname="team-form"
                    // to_email_id={}
                    pageTitle="Careers - Send us your CV"
                  />
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
