import React from "react"
import { Container } from "react-bootstrap"
import "./PlainContent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const PlainContent = (props) => {
  return (
    <div className="grid-gallery-text-module">
      <Container>
        <div className="text-section_landing">
          <h2 className="heading">{props?.title}</h2>
          <p className="description">
            <ContentModule Content={props?.content?.data?.content} />
          </p>
        </div>
      </Container>
    </div>
  )
}

export default PlainContent
